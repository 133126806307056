import React from 'react'
import renderBloks from '@renderBloks'
import { Box, makeStyles } from '@material-ui/core'
import { H3, Text } from '@system'
import PageContainer from '@system/page-container'
import ContentContainer from '@system/content-container'

const useStyles = makeStyles((theme) => ({
  visionBody: {
    padding: '30px',
    paddingBottom: '6rem',
    '& p': {
      lineHeight: '1.5 !important',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0px 0px',
      textAlign: 'center',
    },
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightLight,

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
    display: 'block',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  solutionLinks: {
    width: '20%',
    color: theme.palette.primary.main,
    '& a, & a:hover': {
      border: 'none',
      paddingBottom: '8px',
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  description: {
    '& p': {
      marginBlockStart: '0',
      marginBlockEnd: '0',
      paddingBottom: '14px',
      color: theme.palette.text.secondary,
    },
    '& a': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    '& a, & strong': {
      color: theme.palette.text.tertiary,
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '70%',
    },
  },
}))

const VisionModule = (props) => {
  const classes = useStyles()
  const { shouldRenderH1 } = props.blok
  return (
    <PageContainer>
      <ContentContainer>
        <Box className={classes.visionBody}>
          <H3
            component={shouldRenderH1 ? 'h1' : 'h3'}
            className={classes.headline}
          >
            {props.blok.title}
          </H3>
          <Box className={classes.content}>
            <Text component="div" className={classes.description}>
              {renderBloks(props.blok.text)}
            </Text>
            <Box className={classes.solutionLinks}>
              {renderBloks(props.blok.solutionsMenu)}
            </Box>
          </Box>
        </Box>
      </ContentContainer>
    </PageContainer>
  )
}

export default VisionModule
